import i18n from 'i18next';
import LanguagueDetector from 'i18next-browser-languagedetector';
import {messages} from './languages';

i18n
.use(LanguagueDetector)
.init({

  debug:false,
  defaultNS: ['translations'],
  fallbackLng: 'en',
  ns: ['translations'],
  resources: messages
})
export {i18n}