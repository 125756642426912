const messages = {

  en: {

    translations: {

      Aboutus1: {
        key1: 'Kampanos is a fully remote IT and tech solutions company...'
      },

      Aboutus2: {

        key2: 'that aims to create the best apps, websites, landing pages, tech and visual solutions for our clients.Working at Kampanos means working remotely. We strongly believe that remote jobs are the future and success can be achieved through this.'
      },

      Aboutus3: {

        key3: 'Success comes from passion and motivation...'
      },

      Aboutus4: {

        key4: 'the love for being part of something that makes a difference in the world, that drives people, companies and projects. Grabbing our digital construction tools from home allows us to build amazing things in this incredible and ever-growing digital world.'
      },

      Aboutus5: {

        key5: 'We celebrate loyalty and achievements. '
      },

      Aboutus6: {

        key6: 'We want to help you achieve your goals and your dreams.'
      },


      Aboutus7: {

        key7: 'We offer a slate of services regarding IT...'
      },

      Aboutus8: {

        key8: 'and Technology aswell as App Design and Development, Web Design solutions, Marketing and Social Media, Branding, Graphic Design, Programming and more.'
      },


      Aboutus9: {

        key9: 'ABOUT US'
      },

      Aboutus10: {

        key10: "We use high technology that works.We always"
      },

      Aboutus11: {

        key11: 'get it done.'
      },

      Aboutus12: {

        key12: 'OUR SERVICE'
      },


      Aboutus13: {

        key13: 'Branding & Graphic Design'
      },

      Aboutus14: {

        key14: 'Mobile & Web Design'
      },

      Aboutus15: {

        key15: 'Visual Design'
      },

      Aboutus16: {

        key16: 'User testing'
      },


      Aboutus17: {

        key17: 'Prototypes'
      },

      Aboutus18: {

        key18: 'Presentations'
      },

      Aboutus19: {

        key19: 'Component based Front-End'
      },

      Aboutus20: {

        key20: ' Web & Mobile'
      },

      Aboutus21: {

        key21: ' Native apps'
      },

      Aboutus22: {

        key22: 'API Connections'
      },

      Aboutus23: {

        key23: 'React Native'
      },

      Aboutus24: {

        key24: 'Fast Development'
      },

      Aboutus25: {

        key25: 'Trustable & Fast Delivery'
      },

      Aboutus26: {

        key26: 'OUR TEAM'
      },

      Aboutus27: {

        key27: 'CEO & Chief of Development'
      },

      Aboutus28: {

        key28: 'UX/UI & Visual Designer'
      },

      Aboutus29: {

        key29: 'Web & Front-end Developer'
      },

      Aboutus30: {

        key30: 'Full-Stack Developer'
      },

      
      Aboutus31: {

        key31: 'Software Developer'
      },

      Aboutus32: {

        key32: 'Project Manager'
      },

      Aboutus33: {

        key33: 'Home'
      },

      Aboutus34: {

        key34: 'About us'
      },

      Aboutus35: {

        key35: 'Our service'
      },

      Aboutus36: {

        key36: 'Our story'
      },

      Aboutus37: {

        key37: 'Our team'
      },

      Aboutus38: {

        key38: 'Jobs offer'
      },

      Aboutus39: {

        key39: 'All rights reserved.'
      },

      Aboutus40: {

        key40: 'Home'
      },

      Aboutus41: {

        key41: 'About us'
      },

      Aboutus42: {

        key42: 'Our services'
      },

      Aboutus43: {

        key43: 'Our story'
      },

      Aboutus44: {

        key44: 'Our team'
      },

      Aboutus45: {

        key45: 'Let’s work together!'
      },

      Aboutus46: {

        key46: 'Work with us'
      },


      Aboutus47: {

        key47: 'Terms of use'
      },


      Aboutus48: {

        key48: 'Privacy Policy'
      },


      Aboutus49: {

        key49: 'Cookies'
      },


      Aboutus50: {

        key50: 'Impressum'
      },

      Aboutus51: {

        key51: 'OUR STORY'
      },

      Aboutus52: {

        key52: 'Kampanos is a full remote IT, tech and design solutions company based in Portugal.'
      },

      Aboutus53: {

        key53: 'Our team is'
      },

      Aboutus54: {

        key54: 'focused, professional, experienced'
      },

      Aboutus55: {

        key55: 'but always learning more and open to all kinds of input. Young and cooperative, we are engaged on working in the past present and future.'
      },

      Aboutus56: {

        key56:  'Our pillars are'
      },
      Aboutus57: {

        key57: 'strong'
      },

      Aboutus58: {

        key58:  'and our foundations are'
      },
      Aboutus59: {

        key59: 'strong.'
      },
      Aboutus60: {

        key60:  'We lift up people and develop their skills, we grow together and we learn from each other. We celebrate each other, we celebrate you and your ideas! We encourage hobbies and activities outside the workplace to keep our minds and bodies active and stress-free.'
      },
      Aboutus61: {

        key61: 'strong.'
      },
      Aboutus62: {

        key62: 'We have'
      },
      Aboutus63: {

        key63: 'developers and designers'
      },
      Aboutus64: {

        key64: 'from several countries working together every day to achieve amazing results.We value loyalty and freedom. We create leaders and relationships. We are motivated and passionate.'
      },
      Aboutus65: {

        key65: 'We value loyalty and freedom. We create leaders and relationships. We are motivated and passionate.'
      },



    },
  }

}

export { messages }