const messages = {

  de: {

    translations: {

      Aboutus1: {
        key1: 'Kampanos ist ein vollständig Remote-IT- und Technologielösungsunternehmen...'
      },

      Aboutus2: {

        key2: 'das darauf abzielt, die besten Apps, Websites, Landing Pages, technischen und visuellen Lösungen für unsere Kunden zu erstellen. Bei Kampanos zu arbeiten bedeutet, aus der Ferne zu arbeiten. Wir sind fest davon überzeugt, dass Remote-Jobs die Zukunft sind und dadurch Erfolge erzielt werden können.'
      },

      Aboutus3: {

        key3: 'Erfolg kommt aus Leidenschaft und Motivation...'
      },

      Aboutus4: {

        key4: 'die Liebe, Teil von etwas zu sein, das die Welt bewegt, das Menschen, Unternehmen und Projekte antreibt. Wenn wir unsere digitalen Bauwerkzeuge von zu Hause aus nutzen, können wir in dieser unglaublichen und ständig wachsenden digitalen Welt erstaunliche Dinge bauen.'
      },

      Aboutus5: {

        key5: 'Wir feiern Loyalität und Erfolge. '
      },

      Aboutus6: {

        key6: 'Wir möchten Ihnen helfen, Ihre Ziele und Träume zu erreichen.'
      },


      Aboutus7: {

        key7: 'Wir bieten eine Reihe von Dienstleistungen rund um die IT...'
      },

      Aboutus8: {

        key8: 'und Technologie sowie App-Design und -Entwicklung, Webdesign-Lösungen, Marketing und Social Media, Branding, Grafikdesign, Programmierung und mehr.'
      },

      Aboutus9: {

        key9: 'ÜBER UNS'
      },

      Aboutus10: {

        key10: 'Wir verwenden Spitzentechnologie, die funktioniert. Wir immer'
      },

      Aboutus11: {

        key11: 'mach es fertig.'
      },

      Aboutus12: {

        key12: 'UNSER SERVICE'
      },

      Aboutus13: {

        key13: 'Marken- und Grafikdesign'
      },

      Aboutus14: {

        key14: 'Mobil- und Webdesign'
      },

      Aboutus15: {

        key15: 'Visuelles Design'
      },

      Aboutus16: {

        key16: 'Benutzertests'
      },


      Aboutus17: {

        key17: 'Prototypen'
      },

      Aboutus18: {

        key18: 'Präsentationen'
      },

      Aboutus19: {

        key19: 'Komponentenbasiertes Front-End'
      },

      Aboutus20: {

        key20: 'Web & Mobil'
      },

      Aboutus21: {

        key21: 'Native apps'
      },

      Aboutus22: {

        key22: 'API-Verbindungen'
      },

      Aboutus23: {

        key23: 'React Native'
      },

      Aboutus24: {

        key24: 'Schnelle Entwicklung'
      },

      Aboutus25: {

        key25: 'Zuverlässige und schnelle Lieferung'
      },

      Aboutus26: {

        key26: 'UNSER TEAM'
      },

      Aboutus27: {

        key27: 'CEO & Entwicklungsleiter'
      },

      Aboutus28: {

        key28: 'UX/UI & visueller Designer'
      },

      Aboutus29: {

        key29: 'Web- und Frontend-Entwickler'
      },

      Aboutus30: {

        key30: 'Full-Stack-Entwickler'
      },

      Aboutus31: {

        key31: 'Softwareentwickler'
      },

      Aboutus32: {

        key32: 'Projektmanager'
      },

      Aboutus33: {

        key33: 'Heim'
      },

      Aboutus34: {

        key34: 'Über uns'
      },

      Aboutus35: {

        key35: 'Unser Service'
      },

      Aboutus36: {

        key36: 'Unsere Geschichte'
      },

      Aboutus37: {

        key37: 'Unser Team'
      },

      Aboutus38: {

        key38: 'Stellenangebot'
      },

      Aboutus39: {

        key39: 'Alle Rechte vorbehalten'
      },

     

      Aboutus40: {

        key40: 'Heim'
      },

      Aboutus41: {

        key41: 'Über uns'
      },

      Aboutus42: {

        key42: 'Unsere Dienstleistungen'
      },

      Aboutus43: {

        key43: 'Unsere Geschichte'
      },

      Aboutus44: {

        key44: 'Unser Team'
      },

      Aboutus45: {

        key45: 'Lass uns zusammen arbeiten!'
      },

      Aboutus46: {

        key46: 'Arbeite mit uns'
      },

      Aboutus47: {

        key47: 'Nutzungsbedingungen'
      },


      Aboutus48: {

        key48: 'Datenschutz-Bestimmungen'
      },


      Aboutus49: {

        key49: 'Kekse'
      },


      Aboutus50: {

        key50: 'Impressum'
      },

      Aboutus51: {

        key51: 'UNSERE GESCHICHTE'
      },

      Aboutus52: {

        key52: 'Kampanos ist ein Unternehmen für vollständige Remote-IT-, Technologie- und Designlösungen mit Sitz in Portugal.'
      },

      Aboutus53: {

        key53: 'Unser Team ist'
      },

      Aboutus54: {

        key54: 'fokussiert, professionell, erfahren'
      },

      Aboutus55: {

        key55: 'aber immer dazulernen und offen für alle Arten von Input Jung und kooperativ engagieren wir uns für die Arbeit in der Vergangenheit, Gegenwart und Zukunft.'
      },

      Aboutus56: {

        key56:  'Unsere Säulen sind'
      },
      Aboutus57: {

        key57: 'stark'
      },
      Aboutus58: {

        key58:  'und unsere Grundlagen sind'
      },
      Aboutus59: {

        key59: 'stark.'
      },
      Aboutus60: {

        key60:  'Wir fördern Menschen und entwickeln ihre Fähigkeiten, wir wachsen zusammen und wir lernen voneinander. Wir feiern uns gegenseitig, wir feiern dich und deine Ideen! Wir fördern Hobbys und Aktivitäten außerhalb des Arbeitsplatzes, um unseren Geist und Körper aktiv und stressfrei zu halten.'
      },
      Aboutus61: {

        key61: 'strong.'
      },
      Aboutus62: {

        key62: 'Wir haben'
      },
      Aboutus63: {

        key63: 'developers und designers'
      },
      Aboutus64: {

        key64: 'aus mehreren Ländern, die jeden Tag zusammenarbeiten, um erstaunliche Ergebnisse zu erzielen. Wir schätzen Loyalität und Freiheit. Wir schaffen Führungskräfte und Beziehungen. Wir sind motiviert und leidenschaftlich.'
      },
      Aboutus65: {

        key65: 'Wir legen Wert auf Loyalität und Freiheit. Wir schaffen Führungskräfte und Beziehungen. Wir sind motiviert und leidenschaftlich.'
      },
    },
  }

}

export { messages }