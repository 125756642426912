import React from 'react';
import { Box, Grid, Link, Typography, styled } from '@mui/material';
import { ReactComponent as FacebookIcon } from '../../assets/FacebookIcon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/InstagramIcon.svg';
import { Cookies, Impressum, PrivacyPolicy, TermsService } from '../../components/Modals';
import { i18n } from '../../translate/i18n';
import KampanosBrand from '../../assets/KampanosBrand.svg'
import './footer.css';
const Terms = styled('div')(({ theme }) => ({
  padding: 0,
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    justifyContent: "center",
    paddingRight: 0
  },
  [theme.breakpoints.between('sm', 'md')]: {
    justifyContent: "center",
    paddingRight: 0
  },
  [theme.breakpoints.between('md', 'lg')]: {
    justifyContent: "flex-end",
    paddingRight: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    justifyContent: "flex-end",
    paddingRight: '3rem',
  },
}));

const Footer = () => {
  return (
    <Box sx={{ marginY: '6rem' }}>
      <Grid container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        direction='column'
        rowSpacing={2.5}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          backgroundColor: '#4177B7',
          width: '100%',
          paddingBottom: '0.5rem',
          paddingTop: '2rem',
          textAlign: 'center',
        }}
      >
        <Grid item sx={{textAlign: '-webkit-center'}}>
          {/* <Typography
            color="white" variant="h4"
          >kampanos.</Typography> */}
            <img src={KampanosBrand} alt='Kampanos' title='Kampanos' className='img-fluid' />

        </Grid>
        <Grid item>
          <Grid container
            spacing={2}
            sx={{
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="#header" underline="hover" color="#FAFAFA" >{i18n.t('Aboutus33.key33')}</Link>
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="#about" underline="hover" color="#FAFAFA" >{i18n.t('Aboutus34.key34')}</Link>
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="#service" underline="hover" color="#FAFAFA" >{i18n.t('Aboutus35.key35')}</Link>
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="#story" underline="hover" color="#FAFAFA" >{i18n.t('Aboutus36.key36')}</Link>
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="#team" underline="hover" color="#FAFAFA"  >{i18n.t('Aboutus37.key37')}</Link>
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={1.5}
              lg={1}
            >
              <Link href="https://www.linkedin.com/company/kampanos/" underline="hover" color="#FAFAFA"
              >{i18n.t('Aboutus38.key38')}</Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{
            justifyContent: 'center'
          }}>
            <Grid item>
              <Link href="https://www.linkedin.com/company/kampanos/" ><LinkedinIcon /> </Link>
            </Grid>
            <Grid item>
              <Link href="#"><FacebookIcon /> </Link>
            </Grid>
            <Grid item>
              <Link href="https://www.instagram.com/kampanos.pt/" ><InstagramIcon /> </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            color="#D9DBE1"
          > 2022 © Kampanos {i18n.t('Aboutus39.key39')} </Typography>
        </Grid>

        <Terms>
          <Grid item  >
            <Grid container sx={{ justifyContent: "flex-end" }} spacing={0} >
              <Grid item
                xs={12}
                sm={12}
                md={1.35}
                lg={1}
              >
                <TermsService />
              </Grid>
              <Grid item
                xs={12}
                sm={12}
                md={1.35}
                lg={1}
              >
                <PrivacyPolicy />
              </Grid>
              <Grid item
                xs={12}
                sm={12}
                md={1}
                lg={1}
              >
                <Cookies />
              </Grid>
              <Grid item
                xs={12}
                sm={12}
                md={1}
                lg={1}
              >
                <Impressum />
              </Grid>
            </Grid>
          </Grid>
        </Terms>
      </Grid>
    </Box>
  )
}
export default Footer


