
import {messages as englishMessages} from './en';
import {messages as germanMessages} from './de';

const messages={


...englishMessages,
 ...germanMessages,

}

export {messages}